import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import config from '@quno/patient-journey/config';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import PreviewBar from '@quno/patient-journey/src/components/ui/PreviewBar';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import type { ReactNode } from 'react';
import type { IFooter } from '@quno/patient-journey/types/contentful/generated';
import type { FooterItemType } from '@quno/patient-journey/types/Footer';

type Props = {
  children: ReactNode | ReactNode[];
  preview?: boolean;
  searchIndex?: boolean;
  searchFollow?: boolean;
  title: string;
  metaDescription?: string;
  ogImage?: string;
  footer: IFooter | FooterItemType | null | undefined;
  reducedHeader?: boolean;
  reducedFooter?: boolean;
  noMetaTitle?: string;
  noLayout?: boolean;
  overflowXHidden?: boolean;
  absoluteFooter?: boolean;
};

const Layout = ({
  children,
  preview,
  searchIndex = true,
  searchFollow = true,
  title,
  metaDescription,
  ogImage,
  footer,
  reducedHeader,
  reducedFooter,
  noMetaTitle = '',
  noLayout,
  overflowXHidden = true,
}: Props): JSX.Element => {
  const { asPath } = useRouter();
  const brand = useBrand();

  const pathWithoutParameters = asPath.replace(/\?.*|#.*/g, "$'");

  return (
    <div
      className={clsx('page-wrapper', overflowXHidden && 'overflow-x-hidden')}
    >
      <Head>
        <meta name="viewport" content="width=device-width" />
        <meta
          name="robots"
          content={`${searchIndex ? 'index' : 'noindex'}, ${
            searchFollow ? 'follow' : 'nofollow'
          }`}
        />
        <meta
          name="google-site-verification"
          content={config.defaults.googleVerification}
        />
        {title && <title>{title}</title>}
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        <meta property="og:image" content={brand?.ogImage?.url || ogImage} />
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <link
          rel="icon"
          type="image/png"
          // eslint-disable-next-line react/forbid-dom-props
          href={brand?.favicon?.url || ''}
        />
        <meta
          property="og:url"
          content={`https://${config.app.domains.proxy.production}${pathWithoutParameters}`}
        />
        <meta property="og:title" content={title || brand?.name || undefined} />
        {brand?.usercentricsScriptId && brand?.usercentricsSettingsId ? (
          <>
            <link
              rel="preconnect" // eslint-disable-next-line react/forbid-dom-props
              href="https://app.usercentrics.eu"
            />
            <link
              rel="preconnect"
              // eslint-disable-next-line react/forbid-dom-props
              href="https://api.usercentrics.eu"
            />
            <link
              rel="preload"
              // eslint-disable-next-line react/forbid-dom-props
              href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
              as="script"
            />
          </>
        ) : null}
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/adelle-sans-bold/adelle-sans-bold.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/adelle-sans-regular/adelle-sans-regular.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/nettoweb-black/nettoweb-black.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          // eslint-disable-next-line react/forbid-dom-props
          href="/v2/fonts/nettoweb-regular/nettoweb-regular.subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      {preview && <PreviewBar />}

      {!noLayout && (
        <Header
          isReduced={reducedHeader}
          title={title || ''}
          noMetaTitle={noMetaTitle || ''}
        />
      )}

      <main>{children}</main>

      {!noLayout && (
        <Footer
          fields={(footer as FooterItemType) || null}
          isReduced={reducedFooter}
        />
      )}
    </div>
  );
};

export default Layout;
