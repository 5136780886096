import clsx from 'clsx';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import Link from '@quno/patient-journey/src/components/ui/Link';
import styles from './Footer.module.scss';
import type {
  FooterColType,
  FooterItemType,
} from '@quno/patient-journey/types/Footer';

type Props = {
  fields: FooterColType | FooterItemType;
  isReduced?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Footer = (props: Props): JSX.Element => {
  const brand = useBrand();
  const additionalLinks = brand?.footerLinksCollection?.items;

  return (
    <footer className={styles.footer}>
      <div className={clsx(styles.wrapper)}>
        <ul className={styles.links}>
          {additionalLinks?.map((link, i) => (
            <li key={i}>
              {!!link?.url && (
                <Link href={link.url}>
                  <a className={styles.navigationItem}>{link?.title}</a>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
