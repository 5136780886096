import { useRef } from 'react';
import config from '@quno/patient-journey/config';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { useMedia } from '@quno/patient-journey/src/hooks/useMedia';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import Link from '@quno/patient-journey/src/components/ui/Link';
import Icon from '@quno/patient-journey/src/components/ui/Icon';
import Logo from '@quno/patient-journey/src/components/ui/Logo';
import Dropdown from '@quno/patient-journey/src/components/ui/Dropdown';
import styles from './Header.module.scss';

type Props = {
  isReduced?: boolean;
  onOpen?: () => void;
  title?: string;
  noMetaTitle?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Header = (props: Props): JSX.Element => {
  const brand = useBrand();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { locale, t } = useTranslation();
  const { asPath } = useRouter();
  const isMobile = useMedia('(max-width: 1023px)');

  return (
    <header className={styles.header} ref={containerRef}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div className={styles.logoContainer}>
            {brand && <Logo className={styles.logo} />}
          </div>
          <div className={clsx('', styles.languageWrapper)}>
            <Dropdown
              value={{
                name: t(`langswitch.${locale}`),
                link: '',
              }}
              isIconVisible={!isMobile}
            >
              {config.locales.map((l) => {
                const localeMain = l.main;
                const localeMainCountryCode = localeMain.toUpperCase();

                const iconType =
                  localeMain === 'en'
                    ? 'USUKFlag'
                    : `Countries.${localeMainCountryCode}`;

                return (
                  <li key={localeMain}>
                    <Link href={asPath} locale={localeMain}>
                      <a className={styles.link}>
                        <span className="flex items-center">
                          <Icon
                            className="w-6 h-4 mr-4 shrink-0"
                            type={iconType}
                          />
                          {t(`langswitch.${localeMain}`)}
                        </span>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </Dropdown>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
