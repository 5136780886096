import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import Image from './Image';

type LogoProps = {
  className?: string;
};

const Logo = ({ className }: LogoProps): JSX.Element | undefined => {
  const brand = useBrand();

  const { logo } = brand || {};

  return (
    <Image
      src={logo?.url || ''}
      alt={brand?.name || ''}
      height={logo?.height || undefined}
      width={logo?.width || undefined}
      className={className}
    />
  );
};

export default Logo;
