import clsx from 'clsx';
import { useState } from 'react';
import { Direction } from '@quno/patient-journey/types/Utilities';
import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import Icon from './Icon';
import Link from './Link';
import styles from './Dropdown.module.scss';
import type { MenuType } from '@quno/patient-journey/types/MenuType';

type Props = {
  children?: React.ReactNode;
  value?: MenuType['menuItem'];
  isIconVisible?: boolean;
  currentLocation?: string;
};

const Dropdown = ({
  children,
  value,
  isIconVisible = true,
  currentLocation,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useTranslation();

  return (
    <div
      onMouseOver={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      className={styles.container}
    >
      <div className="flex justify-center items-center py-2 gap-3">
        <span
          className={clsx(
            'py-1 text-base lg:block lg:text-base',
            currentLocation === value?.link?.toLocaleLowerCase() &&
              styles.active,
          )}
        >
          <Link href={`/${locale}/${value?.link}`}>
            <a
              className={clsx(
                'relative no-underline text-primary',
                !value?.link && 'pointer-events-none',
              )}
            >
              <div className={styles.iconWrapper}>
                {isIconVisible && <Icon type={'Globe'} />}
                {value?.name}
              </div>
            </a>
          </Link>
        </span>
        <Icon
          className={clsx(styles.icon, isOpen && styles.iconOpen)}
          width={20}
          height={15}
          type="Angle"
          direction={Direction.BOTTOM}
          color="#585A51"
        />
      </div>
      <div className={clsx(styles.selectContainer, !isOpen && 'hidden')}>
        <ul className={styles.select} onClick={() => setIsOpen(false)}>
          {children}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
